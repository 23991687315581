import React, { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { useTheme } from '../context/ThemeContext';
const TimeBar = ({ totalDuration, isAPIComplete,waitingText }) => {
  const { isDarkMode } = useTheme();
  const [progress, setProgress] = useState(0);
  const [currentText, setCurrentText] = useState('');
  const [fadeProps, setFadeProps] = useSpring(() => ({ opacity: 0 }));

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(prevProgress => {
        if (isAPIComplete) {
          clearInterval(interval);
          return 100;
        }
        if (prevProgress >= 100) {
          clearInterval(interval);
          return 100;
        }

        let increment;
        if (prevProgress >= 90) {
          increment = (100 - prevProgress) * 0.02; // Slow down further after 90%
        } else if (prevProgress >= 80) {
          increment = (100 - prevProgress) * 0.03; // Slow down after 80%
        } else if (prevProgress >= 70) {
          increment = 100 / (totalDuration / 100) * 0.7; // Slow down after 70%
        } else if (prevProgress >= 60) {
          increment = 100 / (totalDuration / 100) * 0.8; // Slow down after 60%
        } else if (prevProgress >= 50) {
          increment = 100 / (totalDuration / 100) * 0.9; // Slow down after 50%
        } else {
          increment = 100 / (totalDuration / 100);
        }

        const newProgress = prevProgress + increment;
        return newProgress >= 99 ? 99 : newProgress; // Ensure it never reaches 100 if isAPIComplete is false
      });
    }, 100);

    return () => clearInterval(interval);
  }, [isAPIComplete, totalDuration]);

  
  useEffect(() => {
    if (waitingText && waitingText.length > 0) {
      setCurrentText(waitingText[Math.floor(Math.random() * waitingText.length)]); // Display the first text immediately
      setFadeProps({ opacity: 1 });
  
      setTimeout(() => {
        setFadeProps({ opacity: 0 });
      }, 4000); // First fade-out after 4 seconds
  
      const changeTextInterval = setInterval(() => {
        setCurrentText(waitingText[Math.floor(Math.random() * waitingText.length)]);
        setFadeProps({ opacity: 1 });
        setTimeout(() => {
          setFadeProps({ opacity: 0 });
        }, 4000);
      }, 5000);
  
      return () => clearInterval(changeTextInterval);
    }
  }, [waitingText, setFadeProps]);
  



  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
    
  };

  const barStyle = {
    position: 'relative',
    width: '100%',
    height: '20px',
    backgroundColor: 'linear-gradient(to right, #0f2027, #203a43, #2c5364)', // Deep space gradient
    borderRadius: '10px',
    overflow: 'hidden',
    marginRight: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(255, 255, 255, 0.2)'
  };

  const fillStyle = {
    height: '100%',
    background: 'linear-gradient(to right, #a55eea, #d291ff, #f3c1ff)', // Lighter purple gradient
    boxShadow: 'inset 0 1px 2px rgba(255, 255, 255, 0.6), 0 0 20px rgba(211, 161, 255, 0.9), 0 0 40px rgba(168, 94, 234, 1), 0 4px 8px rgba(0, 0, 0, 0.5)', // Enhanced light shadow effect
    transition: 'width 0.1s ease-in-out, opacity 0.5s ease-in-out',
    borderRadius: '10px',
    width: `${progress}%`,
    opacity: progress > 0 ? 1 : 0
  }; 



  const textContainerStyle = {
    marginTop: '10px',
    height: '20px'
  };

  const percentageStyle = (isDarkMode) => ({
    fontSize: '16px',
    color: isDarkMode ? '#fff' : '#000', // White for dark mode, black for light mode
    textShadow: isDarkMode 
      ? '0 0 5px rgba(255, 255, 255, 0.7), 0 0 10px rgba(0, 0, 0, 0.5)' 
      : '0 0 5px rgba(0, 0, 0, 0.7), 0 0 10px rgba(255, 255, 255, 0.5)' // Adjusted shadows for light mode
  });
  
  const textStyle = (isDarkMode) => ({
    fontSize: '18px',
    color: isDarkMode ? '#fff' : '#000', // White for dark mode, black for light mode
    textAlign: 'center'
  });


  return (
    <div style={containerStyle}>
      <div style={barStyle}>
        <div style={fillStyle}></div>
      </div>
      <div style={percentageStyle(isDarkMode)}>{Math.floor(progress)}%</div>
      <animated.div style={{ ...textContainerStyle, ...fadeProps }}>
        <div style={textStyle(isDarkMode)}>{currentText}</div>
      </animated.div>
    </div>
  );
};

export default TimeBar;
