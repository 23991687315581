// CheckoutRedirect.js
import React, { useEffect, useState } from 'react';
import { StairazAuth } from '../components/userAuth'; // Assuming you have a custom hook for authentication
import { fetchUserAttributes,getCurrentUser} from "@aws-amplify/auth";
import { generateClient } from 'aws-amplify/api';
import {subscriptionStatusesByUserprofileID, usagesByUserprofileID} from '../graphql/queries';
import TimeBar from '../components/timeBar';
import { useTheme } from '../context/ThemeContext';
const CheckoutRedirect = ({ user }) => {
  const client = generateClient()
  const { isDarkMode } = useTheme();
  const [subscriptionStatus,setSubscriptionStatus] = useState(null)

  const checkActiveSubscription = async () => {
    const param = {
      'userprofileID': user.username,
      'filter': {
        or: [
          { serviceName: { eq: "STAIRAZ Resume Builder AI" } },
          { serviceName: { eq: "STAIRAZ Cover Letter AI" } }
        ]
      },
    };
  
    try {
      const subscriptionData = await client.graphql({ query: subscriptionStatusesByUserprofileID, variables: param });
      const subscriptions = subscriptionData.data.subscriptionStatusesByUserprofileID.items;
  
      const hasActiveSubscription = subscriptions.some(subscription => subscription.active);
  
      setSubscriptionStatus(hasActiveSubscription);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      setSubscriptionStatus(false);
    }
  };
  
  const createCheckoutSession =  async () =>{
    try {
      const userData = await fetchUserAttributes();
        const email = userData.email;
        const urlParams = new URLSearchParams(window.location.search);
        const lookupKeys = urlParams.get('lookup_keys');
        const paymentMode = urlParams.get('payment_mode');
        const prodIds = urlParams.get('prod_ids');
      const response = await fetch('https://75f7w41ox2.execute-api.us-east-2.amazonaws.com/stage/create-checkout-session-bundle', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
          lookup_keys: lookupKeys,
          payment_mode:paymentMode,
          prod_ids:prodIds,
          user_id: user.username,
          email: email
        })
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      
      if (data.url) {
        // Redirect to Stripe Checkout
        window.location.href = data.url;
      } else {
        window.location.href = '/'
        console.error('No URL returned from create-checkout-session endpoint');
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  }

  const handleManageSubscription = async (event) => {
    event.preventDefault();

    try {
      const userData = await fetchUserAttributes();
        const email = userData.email;
      
      const response = await fetch('https://75f7w41ox2.execute-api.us-east-2.amazonaws.com/stage/create-portal-session-userid', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
          user_id: user.username,
          email:email,
        })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      const portalUrl = data.url; // Assuming the API response contains the URL in a field named 'url'

      // Open the URL in a new window
  
      window.location.href = portalUrl;

    } catch (error) {
      console.error('Error creating portal session:', error);
    }
  };
  
  useEffect(() => {
    checkActiveSubscription()
    
    if (subscriptionStatus === false) {
     createCheckoutSession()
  }
  
  }, [user, subscriptionStatus]);
  

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100vh',
    backgroundColor: isDarkMode ? '#121212' : '#f0f8ff', // Dark mode or light mode background color
    color: isDarkMode ? 'white' : 'black', // Dark mode or light mode text color
    textAlign: 'center',
    padding: '20px',
    boxSizing: 'border-box'
  };
  
  return(
  <div style={containerStyle}>
   {!subscriptionStatus ? (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%'
        }}>
          <div style={{ width: "400px" }}>
            <TimeBar totalDuration={3000} />
          </div>
        </div>
      ) : (
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100vh',
          backgroundColor: 'none',
          textAlign: 'center',
          padding: '20px',
          boxSizing: 'border-box'
        }}>
          <p style={{
            fontSize: '18px',
            marginBottom: '20px',
            lineHeight: '1.5'
          }}>
            You already have an active service. Build your resume now or manage your subscription.
          </p>
          <div style={{
            display: 'flex',
            gap: '10px'
          }}>
            <button style={{
              padding: '10px 20px',
              fontSize: '16px',
              color: 'black',
              backgroundColor: 'lightblue',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              transition: 'background-color 0.3s, color 0.3s'
            }}
            onClick={() => window.location.href = '/career/service/resume-builder-ai/'}
            onMouseOver={(e) => {
              e.target.style.backgroundColor = 'lightblue';
            }}
            onMouseOut={(e) => {
              e.target.style.backgroundColor = 'lightblue';
            }}>
              Build Your Resume
            </button>
            <button style={{
              padding: '10px 20px',
              fontSize: '16px',
              color: 'black',
              backgroundColor: 'lightblue',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              transition: 'background-color 0.3s, color 0.3s'
            }}
            onClick={handleManageSubscription}
            onMouseOver={(e) => {
              e.target.style.backgroundColor = 'lightblue';
            }}
            onMouseOut={(e) => {
              e.target.style.backgroundColor = 'lightblue';
            }}>
              Manage Subscription
            </button>
          </div>
        </div>
        
      )}
  </div>)
  
};

export default StairazAuth(CheckoutRedirect);
